/**
 * @prettier
 * @flow
 */

/** TODO: THIS COMPONENT IS INCOMPLETE AND IN PROTOTYPE USAGE! DO NOT USE IT IN PRODUCTION! */
import { Breadcrumb as SUIRBreadcrumb } from 'semantic-ui-react';
import BreadcrumbDivider from './src/BreadcrumbDivider';
import BreadcrumbSection from './src/BreadcrumbSection';
import { Icon, ContextMenu } from 'liana-ui/components/';
import { Size } from 'liana-ui/types';

type Section = {
	page?: React.Node,
	link?: string,
	contextMenu?: React.PropsOf<ContextMenu>,
	testID?: string
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/breadcrumb/ */
component Breadcrumb(
	/** 
	 	A breadcrumb must have sections for page navigation. 
		PROPS[IntlComponent=/localization/, ContextMenu=/components/menu/context-menu/] 
	*/
	sections: Array<Section>,
	/** A breadcrumb can have an icon. */
	icon?: string,
	/** A breadcrumb can be different size. */
	size?: Size,
	/** Test ID for testing */
	testID?: string
) {
	// Generate main part of trail
	const getSections = () => {
		if (Array.isArray(sections) && sections.length > 0) {
			let trail: any = [];
			if (icon) {
				trail.push(<Icon name={icon} testID={`Breadcrumb::Icon`} />);
			}
			for (let i = 0; i < sections.length - 1; i++) {
				// $FlowIssue[prop-missing] - defaultProps
				trail.push(
					<BreadcrumbSection
						text={sections[i].page}
						link={sections[i].link}
						contextMenu={sections[i].contextMenu}
						key={`section-${i}`}
						testID={sections[i].testID || `Breadcrumb::Section::${i}`}
					/>
				);
				if (i < sections.length - 1) {
					trail.push(<BreadcrumbDivider key={`divider-${i}`} testID={`Breadcrumb::Divider::${i}`} />);
				}
			}
			// $FlowIssue[prop-missing] - defaultProps
			trail.push(
				<BreadcrumbSection
					text={sections[sections.length - 1].page}
					key='section-current'
					contextMenu={sections[sections.length - 1].contextMenu}
					testID={sections[sections.length - 1].testID || `Breadcrumb::Section::${sections.length - 1}`}
				/>
			);
			return trail;
		}
		return null;
	};

	return (
		<SUIRBreadcrumb as='nav' size={size} testID={testID}>
			{getSections()}
		</SUIRBreadcrumb>
	);
}

export default (React.memo(Breadcrumb): React.AbstractComponent<React.PropsOf<Breadcrumb>, mixed>);
