/**
 * @prettier
 * @flow
 */

import { useEffect, useState, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMatch } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import * as jose from 'jose';
import * as Sentry from '@sentry/react';
import { ApolloClient, ApolloProvider, InMemoryCache, HttpLink } from '@apollo/client';
import { Fetch, AppUtils } from 'liana-ui/definitions';
import { AppChunkError, Message, AppScroll } from 'liana-ui/components';
import Provider from 'liana-ui/lib/provider/Provider';
import AppContext from 'context/AppContext';
import MeContext from 'context/MeContext';
import Main from './Main';
import { GetData, GetMe } from '../../graphql';
import { RESELLER } from '../../definitions/enums';
import { GetInstallerConfig } from 'graphql/Installer.graphql';
import { getLinksByLanguage } from '@lianacloud/ui-common/dist';

type Props = {
	children: React.Node
};

const Component = (props: Props) => {
	const { isLoading, isAuthenticated, error, user, loginWithRedirect, getAccessTokenSilently } = useAuth0();
	const [accessToken, setAccessToken] = useState('');

	const atHome = useMatch('/home');
	const appCtx = useContext(AppContext);
	const meCtx = useContext(MeContext);
	const [userData, setUserData] = useState(null);
	const [errors, setErrors] = useState(null);
	const [currentOrganizationId, setCurrentOrganizationId] = useState(null);

	let settings = Object.assign({}, process.env.language); // Legacy hackish
	let [client, setClient] = useState(null);
	let debug = (msg, data) => {
		if (typeof process === 'object' && typeof process.env === 'object' && process.env.NODE_ENV === 'development') {
			console.info(msg, data);
		}
	};

	const replaceCountryCodeFieldName = (countries) => {
		const newArray = countries.map(({ code: isoAlpha2, ...rest }) => ({
			isoAlpha2,
			...rest
		}));
		return newArray;
	};

	const catchError = (error) => {
		console.error(error);
		setErrors(error);
	};

	const getAccessToken = async () => {
		try {
			const token = await getAccessTokenSilently();
			const claims = jose.decodeJwt(token);
			const roleClaim = claims['https://lianacloud.com/role'];
			const roleStrings = roleClaim?.split('_');
			const role = roleStrings && roleStrings.length > 1 ? roleStrings[1] : 'USER';
			meCtx.setState((prev) => ({ ...prev, adminRole: role }));
			setAccessToken(token);
		} catch (e) {
			catchError(e);
		}
	};

	const createInstallerConfig = (data, reseller) => {
		const enabledSolutionTypes = data.types
			.map((solutionType) => {
				if (solutionType.enabled) return solutionType.type;
			})
			.filter((type) => type !== undefined);

		const isSmoothInstallation = () => {
			const isCMSEnabled = enabledSolutionTypes.includes('cms');
			const isTagomoEnabled = reseller === RESELLER.TAGOMO ? isCMSEnabled : true;
			return data.enabled && reseller !== RESELLER.EVENTILLA && isTagomoEnabled;
		};

		return {
			isEnabled: isSmoothInstallation(),
			enabledSolutionTypes
		};
	};

	const apolloClient = () => {
		AppUtils.init(); // Initialize various DOM listener
		let base = process.env.baseUrl || '/';

		Fetch.get(`${base}json/config.json`).then((config) => {
			debug('Dynamic config loaded!', config);
			const httpLink = new HttpLink({
				uri: config.API_URL || 'https://base.127.0.0.1.nip.io/graphql',
				fetchOptions: {
					mode: 'cors'
				},
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken ? `Bearer ${accessToken}` : ''
				}
			});
			let client = new ApolloClient({
				link: httpLink,
				credentials: 'include',
				cache: new InMemoryCache({
					typePolicies: {
						OrganizationAccount: {
							keyFields: ['id', 'organizationId']
						},
						Query: {
							fields: {
								organization: {
									merge: true
								},
								solution: {
									merge: true
								}
							}
						}
					}
				}),
				defaultOptions: {
					watchQuery: {
						fetchPolicy: 'network-only',
						pollInterval: 8640000
					},
					query: {
						fetchPolicy: 'network-only'
					}
				}
			});

			setClient(client);

			// Get languages, timezones, countries etc. This will be done only once per session
			Promise.all([
				client.query({ query: GetData }),
				client.query({ query: GetMe }),
				client.query({ query: GetInstallerConfig })
			])
				.then((data) => {
					const me = data[1].data.me;

					let meLinks = {
						...me.links,
						privacyPolicy: getLinksByLanguage(me.language).privacyPolicyLink
					};

					let usrData = {
						...me,
						links: meLinks,
						supportSite: getLinksByLanguage(me.language).supportSiteLink
					};
					if (currentOrganizationId) {
						usrData.selectedOrganization = currentOrganizationId;
					}
					setUserData(usrData);

					const app = data[0].data;
					let countries;
					if (app.countries && app.countries.length > 0) {
						countries = replaceCountryCodeFieldName(app.countries);
					}

					let appData = {
						...app,
						config,
						installerConfig: createInstallerConfig(
							data[2].data.smoothInstallationConfiguration,
							usrData.selectedOrganizationReseller
						),
						countries: countries,
						ready: true
					};
					meCtx.setState((prev) => ({
						...prev,
						...usrData
					}));
					appCtx.setState((appDataOld) => ({ ...appDataOld, ...appData }));

					debug('User config loaded!', usrData);
					debug('Application config loaded!', appData);
					return null;
				})
				.catch((error) => {
					// Note: Best guess error - Usually session has expired
					// Note: Intl isn't even available yet at this point
					console.error(error);
					setErrors(error);
				});

			if (config.ENVIRONMENT !== 'local') {
				Sentry.init({
					dsn: config.SENTRY_APP_DSN,
					integrations: [
						new Sentry.BrowserTracing({
							// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
							tracePropagationTargets: [config.APP_URL]
						})
					],
					// Performance Monitoring
					tracesSampleRate: 0.1, // Capture 10% of the transactions
					environment: config.ENVIRONMENT || 'unknown'
				});
			}

			return null;
		});
	};

	useEffect(() => {
		if (accessToken === '') {
			return;
		}
		apolloClient();
	}, [accessToken]);

	useEffect(() => {
		if (!user || !isAuthenticated) {
			return;
		} else {
			const adminAppOrgId = user['https://lianacloud.com/applicationOrganizationId'];
			if (adminAppOrgId) {
				setCurrentOrganizationId(String(adminAppOrgId));
			}
		}
		getAccessToken();
	}, [isAuthenticated, user]);

	useEffect(() => {
		if (!isLoading && !isAuthenticated) {
			const forceLogin = async () => {
				await loginWithRedirect({
					appState: { returnTo: window.location.origin }
				});
			};
			forceLogin();
		}
	}, [isLoading]);

	useEffect(() => {
		if (!isLoading && !isAuthenticated) {
			const forceLogin = async () => {
				await loginWithRedirect({
					appState: { returnTo: window.location.origin }
				});
			};
			forceLogin();
		}
	}, []);

	if (isLoading) {
		return <div className='ui active loader' />;
	}
	if (error) {
		return (
			<Provider language='en'>
				<div className='main-column'>
					<Message
						error
						layout='big'
						header={<FormattedMessage id='notifications.error' />}
						content={error.message}
					/>
				</div>
			</Provider>
		);
	}

	if (isAuthenticated) {
		return errors ? (
			<Provider language='en'>
				<div className='main-column'>
					<Message error layout='big' header={<FormattedMessage id='notifications.error' />} />
				</div>
			</Provider>
		) : !appCtx.ready ? (
			<div className='ui active loader' />
		) : (
			<Provider
				language={
					meCtx && meCtx.language && settings.locales.hasOwnProperty(meCtx.language)
						? meCtx.language
						: userData && userData.language && settings.locales.hasOwnProperty(userData.language)
						? userData.language
						: 'en'
				}
				{...settings}
			>
				<ApolloProvider client={client}>
					<AppChunkError>
						<AppScroll />
						<Main active={atHome}>{props.children}</Main>
					</AppChunkError>
				</ApolloProvider>
			</Provider>
		);
	} else {
		return <div className='ui active loader' />;
	}
};

Component.displayName = 'App';

export default Component;
