/**
 * @prettier
 * @flow
 */

import { FormattedMessage } from 'react-intl';
import { Loader } from 'liana-ui/components/';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/icon */
component Page(
	/** Content as children. */
	children?: React.Node,
	/** Page can be loading. */
	loading?: boolean = false,
	/** Page loading text. */
	loadingText?: React.Node = <FormattedMessage id='component.loader.loading' />,
	/** Test ID used for testing. */
	testID?: string
) {
	return (
		<main className='main-column' id='main-column' data-testid={testID}>
			{loading ? <Loader active centerInEmptySpace text={loadingText} /> : children}
		</main>
	);
}

export default (React.memo(Page): React.AbstractComponent<React.PropsOf<Page>, mixed>);
