/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Message as SUIRMessage } from 'semantic-ui-react';
import { Icon, Loader } from 'liana-ui/components/';
import MessageHeader from './src/MessageHeader';
import MessageContent from './src/MessageContent';
import { Size, Spacing } from 'liana-ui/types';

type IconData = {
	small: string,
	big: string,
	color: string
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/message/ */
component Message(
	/** A message must have content. */
	content: React.Node,
	/**
		A big message can have a header. Not available for small layout variation.
		PROPS[React.Node=/localization/]
	*/
	header?: React.Node,
	/**
		A message can have an icon.
		DATA[https://react.semantic-ui.com/elements/icon/]
	 */
	icon?: string,
	/** A message can have different layouts. */
	layout?: 'small' | 'big' | 'content' = 'small',
	/** A message can be formatted to display information. */
	info?: boolean = false,
	/** A message can be formatted to display a positive message.  */
	success?: boolean = false,
	/** A message can be formatted to display warning messages.  */
	warning?: boolean = false,
	/** A message can be formatted to display a negative message.  */
	error?: boolean = false,
	/** A message can be formatted to display loading state.  */
	loading?: boolean = false,
	/** A message can be formatted to appear to be off.  */
	off?: boolean = false,
	/** A message can only take up the width of its content. */
	compact?: boolean,
	/** A message can be compressed to have less padding. */
	compressed?: boolean,
	/** A big layout message can be formatted to be vertical. */
	vertical?: boolean = false,
	/**
		A message margins can be removed around the message.
		VALUES['all' | 'top' | 'bottom']
	*/
	removeMargins?: Spacing,
	/** A message can be diffreent size. */
	size?: Size = Size.Small,
	/** Test ID for testing */
	testID?: string
) {
	// Assign classes
	let classes = classNames({
		vertical: vertical,
		icon: layout === 'big',
		off: off,
		compressed: compressed,
		'remove-top-margin': removeMargins === Spacing.Top || removeMargins === Spacing.All,
		'remove-bottom-margin': removeMargins === Spacing.Bottom || removeMargins === Spacing.All,
		'remove-margins': removeMargins === Spacing.All
	});

	// Get message content based on layout
	const getLayout = () => {
		let iconData = getIcon();
		return layout === 'big' ? (
			<>
				{loading && !icon ? (
					<span className='icon'>
						<Loader active inline />
					</span>
				) : iconData && layout !== 'content' ? (
					<Icon name={iconData.big} color={iconData.color} spinning={loading} />
				) : null}
				<MessageContent>
					{header ? <MessageHeader size='large'>{header}</MessageHeader> : null}
					{content}
				</MessageContent>
			</>
		) : (
			<>
				{header && layout === 'content' ? <MessageHeader>{header}</MessageHeader> : null}
				{loading && !icon ? (
					<span className='icon'>
						<Loader active inline size={Size.Tiny} />
					</span>
				) : iconData?.small && layout !== 'content' ? (
					<Icon name={iconData.small} color={iconData.color} spinning={loading} />
				) : null}
				{content}
			</>
		);
	};

	// Get icon based on message type
	const getIcon = (): IconData | void => {
		if (layout !== 'content') {
			switch (true) {
				case info:
					return {
						small: icon || 'fa-info-circle',
						big: icon || 'fa-info-circle',
						color: 'purple'
					};
				case success:
					return {
						small: icon || 'fa-check',
						big: icon || 'fa-check',
						color: 'green'
					};
				case warning:
					return {
						small: icon || 'fa-circle-exclamation',
						big: icon || 'fa-circle-exclamation',
						color: 'yellow'
					};
				case error:
					return {
						small: icon || 'fa-triangle-exclamation',
						big: icon || 'fa-triangle-exclamation',
						color: 'red'
					};
				default:
					return {
						small: icon || '',
						big: icon || '',
						color: 'grey'
					};
			}
		}
		return undefined;
	};

	// Get color on message type
	const getColor = () => {
		switch (true) {
			case info:
				return 'purple';
			case success:
				return 'green';
			case warning:
				return 'yellow';
			case error:
				return 'red';
			default:
				return null;
		}
	};

	let message = (
		<SUIRMessage color={getColor()} size={size} compact={compact} className={classes} testID={testID}>
			{getLayout()}
		</SUIRMessage>
	);

	return message;
}

export default (React.memo(Message): React.AbstractComponent<React.PropsOf<Message>, mixed>);
