/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import ResizeSensor from 'css-element-queries/src/ResizeSensor';
import { Size } from 'liana-ui/types';
import { useRef, useState, useEffect } from 'react';
import { Table as SUIRTable } from 'semantic-ui-react';
import TableRow from './src/TableRow';
import TableHeaderCell from './src/TableHeaderCell';
import TableBodyCell from './src/TableBodyCell';
import TableFooterCell from './src/TableFooterCell';
import TableUtils from './src/TableUtils';

type BodyRowsWithHeader = Array<{
	id: string, // TODO: Undocumented prop
	cells: Array<{
		header?: any, // TODO: Undocumented prop
		...React.PropsOf<TableBodyCell>
	}>
}>;

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/table/ */
component Table(
	/** TODO: Undocumented prop */
	className?: string,
	/**
		A table can have it's columns defined so whole columns can be controlled.
		DATA[json/table/columns.json]
	*/
	columns?: Array<{
		name: string,
		width: number,
		collapsing: boolean,
		minDevice: string,
		textAlign: string,
		singleLine: boolean,
		breakWord: boolean,
		noWrap: boolean
	}>,
	/**
		An table can have a single or multiple header rows.
		DATA[json/table/header-rows.json]
		PROPS[IntlComponent=/language/localisation/, Popup=/components/modals/popup/]
	*/
	headerRows?: Array<{
		cells: Array<React.PropsOf<TableHeaderCell>>
	}>,
	/**
	 	A table can have body rows.
		DATA[json/table/body-rows.json]
		PROPS[IntlComponent=/language/localisation/, Popup=/components/modals/popup/]
	*/
	bodyRows: BodyRowsWithHeader,
	/** Mapped over any data provided for bodyRows property. Should return body row object for body rows. */
	renderBodyRow?: (data: any) => {
		cells: Array<React.PropsOf<TableBodyCell>>
	},
	/**
		An table can have a single or multiple footer rows.
		DATA[json/table/footer-rows.json]
		PROPS[IntlComponent=/language/localisation/, Popup=/components/modals/popup/]
	*/
	footerRows?: Array<{
		cells: Array<React.PropsOf<TableFooterCell>>
	}>,
	/** A table can reduce its complexity to increase readability. */
	basic?: boolean = false,
	/** A table may be divided into individual cells. */
	celled?: boolean = true,
	/** A table may sometimes need to be more compact to make more rows visible at a time. */
	compact?: boolean = false,
	/** A table can be more fitted by removing first and last cell paddings. */
	fitted?: boolean = false,
	/** An table can stack columns on mobile. */
	stackable?: boolean,
	/** A table may sometimes need to be more padded for legibility. */
	padded?: boolean = false,
	/** A table may be formatted to emphasize a first column that defines a row content. */
	definition?: boolean = false,
	/** A table can be formatted to display complex structured data. */
	structured?: boolean = false,
	/** A table can specify that its cell contents should remain on a single line and not wrap. */
	singleLine?: boolean = false,
	/** A table can remove all it's margins. */
	removeMargins?: boolean,
	/** Overflow scrolling can be disabled. */
	disableScroll?: boolean = false,
	/** A table can have height. */
	height?: number,
	/** A table can be smaller in size. */
	size?: Size,
	/** Test ID for testing. */
	testID: string = 'Table'
) {
	// Variables, states and refs
	const scrollWrapperRef = useRef<HTMLDivElement | null>(null);
	const tableRef = useRef<HTMLTableElement | null>(null);
	let [horizontalScrollActive, setHorizontalScrollActive] = useState(true);

	// Set horizontal sticky and scrolling
	useEffect(() => {
		if (scrollWrapperRef.current && tableRef.current) {
			setHorizontalSticky();
			new ResizeSensor(scrollWrapperRef.current, () => {
				setHorizontalSticky();
			});
			new ResizeSensor(tableRef.current, () => {
				setHorizontalSticky();
			});
		}
	}, []);

	// Clear sensors on unmount
	useEffect(
		() => () => {
			ResizeSensor.detach(scrollWrapperRef.current);
			ResizeSensor.detach(tableRef.current);
		},
		[]
	);

	const setHorizontalSticky = () => {
		if (scrollWrapperRef.current && tableRef.current) {
			const scrollWrapperWidth = scrollWrapperRef.current.getBoundingClientRect().width,
				// $FlowIssue[incompatible-use] Already checked above
				tableWidth = tableRef.current.getBoundingClientRect().width;
			if (tableWidth > scrollWrapperWidth) {
				setHorizontalScrollActive(true);
				// $FlowIssue[incompatible-call] Already checked above
				TableUtils.enableHorizontalDrag(scrollWrapperRef.current);
			} else {
				setHorizontalScrollActive(false);
				// $FlowIssue[incompatible-call] Already checked above
				TableUtils.disableHorizontalDrag(scrollWrapperRef.current);
			}
		}
	};

	// Factory function for transforming data
	// TODO: Why is this needed? This is currently done twice each render; very slow
	let body: any = [];
	if (bodyRows && bodyRows.length > 0) {
		if (typeof renderBodyRow === 'function') {
			for (let i = 0; i < bodyRows.length; i++) {
				body[i] = renderBodyRow(bodyRows[i]);
			}
		} else {
			body = bodyRows;
		}
	}

	const renderHeaderRows = () => {
		// Check if exists
		if (!headerRows) {
			return null;
		}

		let rows = [];
		headerRows.map((row, rowIndex) => {
			let cells = [];
			row.cells.map((cell, cellIndex) => {
				if (Array.isArray(columns) && columns.length > 0) {
					// $FlowIgnore - Flow can't handle spread attributes
					cell = { ...columns[cellIndex], ...cell };
				}

				cells.push(
					<TableHeaderCell
						key={`tableheadercell-${rowIndex}-${cellIndex}`}
						content={cell.content}
						colSpan={cell.colSpan}
						collapsing={cell.collapsing}
						width={cell.width}
						textAlign={cell.textAlign}
						verticalAlign={cell.verticalAlign}
						minDevice={cell.minDevice}
						popup={cell.popup}
						className={cell.className}
					/>
				);
			});

			rows.push(<SUIRTable.Row key={`tableheaderrow-${rowIndex}`}>{cells}</SUIRTable.Row>);
		});

		return <SUIRTable.Header>{rows}</SUIRTable.Header>;
	};

	const renderBodyRows = (brwh: BodyRowsWithHeader) => {
		if (!brwh || !Array.isArray(brwh)) {
			return null;
		}

		let rows: Array<any> = [];
		brwh.map((row) => {
			let cells: Array<any> = [];
			if (Array.isArray(row.cells)) {
				row.cells.map((cell, cellIndex) => {
					if (Array.isArray(columns) && columns.length > 0) {
						// $FlowIgnore - Flow can't handle spread attributes
						cell = { ...columns[cellIndex], ...cell };
					}
					cells.push(
						<TableBodyCell
							key={`tablebodycell-${row.id}-${cellIndex}`}
							content={cell.content}
							colSpan={cell.colSpan}
							rowSpan={cell.rowSpan}
							collapsing={cell.collapsing}
							success={cell.success}
							warning={cell.warning}
							error={cell.error}
							active={cell.active}
							off={cell.off}
							disabled={cell.disabled}
							width={cell.width}
							textAlign={cell.textAlign}
							verticalAlign={cell.verticalAlign}
							singleLine={!cell.header ? cell.singleLine : false}
							breakWord={!cell.header ? cell.breakWord : false}
							noWrap={!cell.header ? cell.noWrap : false}
							minDevice={cell.minDevice}
							popup={cell.popup}
							className={cell.className}
						/>
					);
				});
				rows.push(
					// $FlowFixMe - Don't blindly pass spread attributes forward
					<TableRow key={`tablebodyrow-${row.id}`} {...row}>
						{cells}
					</TableRow>
				);
			}
		});

		return <SUIRTable.Body>{rows}</SUIRTable.Body>;
	};

	const renderFooterRows = () => {
		// Check if exists
		if (!footerRows) {
			return null;
		}

		let rows = [];
		footerRows.map((row, rowIndex) => {
			let cells = [];
			row.cells.map((cell, cellIndex) => {
				if (Array.isArray(columns) && columns.length > 0) {
					// $FlowIssue - Flow can't deal with spread attributes
					cell = { ...columns[cellIndex], ...cell };
				}

				cells.push(
					<TableFooterCell
						key={`tablefootercell-${rowIndex}-${cellIndex}`}
						content={cell.content}
						colSpan={cell.colSpan}
						collapsing={cell.collapsing}
						width={cell.width}
						textAlign={cell.textAlign}
						verticalAlign={cell.verticalAlign}
						minDevice={cell.minDevice}
						popup={cell.popup}
						className={cell.className}
					/>
				);
			});

			rows.push(<SUIRTable.Row key={`tablefooterrow-${rowIndex}`}>{cells}</SUIRTable.Row>);
		});

		return <SUIRTable.Footer>{rows}</SUIRTable.Footer>;
	};

	const countColumns = () => {
		let cols = columns?.length,
			columnNames = {
				1: 'one',
				2: 'two',
				3: 'three',
				4: 'four',
				5: 'five',
				6: 'six',
				7: 'seven',
				8: 'eight',
				9: 'nine',
				10: 'ten',
				11: 'eleven',
				12: 'twelve',
				13: 'thirteen',
				14: 'fourteen',
				15: 'fifteen',
				16: 'sixteen'
			};
		// $FlowFixMe[invalid-computed-prop] - Dynamic values are not allowed as object keys
		return columnNames[cols];
	};

	// Assign wrapper classes
	const wrapperClasses = classNames('table-wrapper', {
		basic: basic,
		structured: structured,
		scrollable: horizontalScrollActive && !disableScroll,
		'remove-margins': removeMargins
	});

	// Assign table classes
	const cols = countColumns();
	const tableClasses = classNames(
		'ui',
		{
			[`${cols} column`]: cols,
			// $FlowFixMe[invalid-computed-prop] - Dynamic values are not allowed as object keys
			[size]: size,
			celled: celled && !definition,
			padded: padded,
			structured: structured,
			unstackable: !stackable ? (disableScroll ? false : true) : undefined,
			stackable: stackable,
			definition: definition,
			'single line': singleLine,
			basic: basic,
			compact: compact,
			fitted: fitted
		},
		'table',
		className
	);

	let style = {};
	if (height) {
		style = { height: `${height}px` };
	}

	let table = (
		<div style={style} ref={scrollWrapperRef} className={wrapperClasses} data-testid={testID}>
			<table ref={tableRef} className={tableClasses}>
				{renderHeaderRows()}
				{renderBodyRows(body)}
				{renderFooterRows()}
			</table>
		</div>
	);

	return table;
}

export default (React.memo(Table): React.AbstractComponent<React.PropsOf<Table>, mixed>);
