/**
 * @prettier
 * @flow
 */

import { useEffect, useState } from 'react';
import { GridStack } from 'liana-ui/lib/gridstack';
import { DashboardItem, Widget } from 'liana-ui/components';

type GridStackItem = {
	id: string,
	x: number,
	y: number,
	width: number,
	height: number
};

/** COMPONENT BASED ON: https://github.com/gridstack/gridstack.js/tree/develop/doc */
component Dashboard(
	/** Widgets to render */
	widgets: Array<Widget>,
	/** Turns on animation */
	animate?: boolean = true,
	/** Dashboards min width */
	minWidth?: number | string = 667,
	/** Display dashboard in public mode. Disables for example all editing. */
	publicMode?: boolean,
	/** Test ID for testing. */
	testID: string = 'Dashboard',
	/** Widget on add callback */
	onAdd: (Array<GridStackItem> | false) => mixed,
	/** Dashboard on change callback */
	onChange: (Array<GridStackItem> | false, mixed) => mixed,
	/** Widget on delete callback */
	onDelete: (Array<GridStackItem> | false) => mixed
) {
	const [gs, setGridStack] = useState(null);

	const getScreenSizes = () => {
		const classes = document.querySelector('html')?.classList;
		return {
			isMobile: classes && classes.contains('mobile'),
			isTablet: classes && classes.contains('tablet'),
			isComputer: classes && classes.contains('computer'),
			isLargescreen: classes && classes.contains('largescreen'),
			isWidescreen: classes && classes.contains('widescreen')
		};
	};

	const getColumns = () => {
		const { isMobile, isTablet, isComputer } = getScreenSizes();
		switch (true) {
			case isMobile:
				return 1;
			case isTablet || isComputer:
				return 2;
			default:
				return 3;
		}
	};

	const getHeight = () => {
		const { isWidescreen, isLargescreen, isComputer, isTablet, isMobile } = getScreenSizes();
		switch (true) {
			case isWidescreen:
				return 450;
			case isLargescreen:
				return 425;
			case isComputer:
				return 400;
			case isTablet:
				return 440;
			case isMobile:
				return 460;
			default:
				return 480;
		}
	};

	useEffect(() => {
		const pointerOnly = document.querySelector('html')?.classList.contains('pointer-device');
		const initHeight = getHeight();
		const initCols = getColumns();
		const gridstack = GridStack.init({
			handle: '.actionheader-wrapper, .add-widget',
			animate: animate,
			cellHeight: initHeight,
			minWidth: minWidth,
			margin: 13,
			disableDrag: !pointerOnly || publicMode,
			disableResize: !pointerOnly || publicMode
		});

		gridstack.column(initCols);
		setGridStack(gridstack);

		const serializeItems = (items: Array<any>) =>
			Array.isArray(items)
				? items.map((item) => ({
						id: item.id,
						x: item.x,
						y: item.y,
						width: item.width,
						height: item.height
					}))
				: false;

		gridstack.on('change', (_, items) => {
			// We dont want onChange to run on form changes
			if (typeof onChange === 'function' && items) {
				onChange(
					serializeItems(items),
					gridstack
						.getGridItems()
						.map((item) => ({
							id: item.dataset.gsId,
							x: parseInt(item.dataset.gsX, 10),
							y: parseInt(item.dataset.gsY, 10),
							width: parseInt(item.dataset.gsWidth, 10),
							height: parseInt(item.dataset.gsHeight, 10)
						}))
						.sort((a, b) => a.y - b.y || a.x - b.x)
				);
			}
		});

		gridstack.on('added', (_, items) => {
			if (typeof onAdd === 'function') {
				onAdd(serializeItems(items));
			}
		});

		gridstack.on('removed', (_, items) => {
			if (typeof onDelete === 'function') {
				onDelete(serializeItems(items));
			}
		});

		const handleWindowResize = () => {
			const cols = getColumns();
			if (!gridstack.el.classList.contains(`grid-stack-${cols}`)) {
				gridstack.column(cols);
			}
		};

		window.addEventListener('resize', handleWindowResize);

		return () => {
			gridstack.off('removed'); // Prevent deleting all items in unmount
			gridstack.off('change');
			window.removeEventListener('resize', handleWindowResize);
			gridstack.destroy(false);
			setGridStack(null);
		};
	}, []);

	return (
		<div className='dashboard grid-stack-wrapper' data-testid={testID}>
			<div className='grid-stack'>
				{gs &&
					widgets &&
					widgets.map((widget, index) => (
						<DashboardItem
							// $FlowFixMe - React props shouldn't be accessed directly
							key={`widget-${widget.props.id}`}
							// $FlowFixMe - React props shouldn't be accessed directly
							{...widget.props}
							// $FlowFixMe - React props shouldn't be accessed directly
							testID={widget.props.testID || `Dashboard::DashboardItem::${index}`}
						>
							{widget}
						</DashboardItem>
					))}
			</div>
		</div>
	);
}

export default (React.memo(Dashboard): React.AbstractComponent<React.PropsOf<Dashboard>, mixed>);
