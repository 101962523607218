/**
 * @prettier
 * @flow
 */

import { FormattedMessage } from 'react-intl';
import { Dropdown } from 'semantic-ui-react';
import { Icon, Segment, Navigation, Popup } from 'liana-ui/components/';
import { Size, Spacing } from 'liana-ui/types';
import type { Page } from 'liana-ui/components/navigation/Navigation';

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/dropdown/ and https://react.semantic-ui.com/collections/menu/ */
component NavigationMenu(
	/** If is open */
	open: boolean = false,
	/** Mobile navigation tools (only visible on mobile and tablet screen sizes). Should be the same as above the main navigation in SidePanel on desktop. */
	tools?: React.Node,
	/** Mobile navigation data (only visible on mobile and tablet screen sizes). Array of pages object: DATA[json/navigation/navigation.json] */
	navigation?: Array<Page>,
	/** Position, with and heigh of dropdown menu */
	style?: { [string]: string | number },
	/** Object of properties for Popup */
	popup?: React.PropsOf<Popup>,
	/** Use plaintext translations */
	raw: boolean = false,
	/** Called on when clicking on a link. */
	onLinkClick?: (event: SyntheticEvent<>, data: any) => void,
	/** Called on menu open. */
	onOpen?: (event: SyntheticEvent<>, data: { id: string }) => void,
	/** Called on menu close. */
	onClose?: (event: SyntheticEvent<>, data: { id: string }) => void
) {
	// Render nothing if empty
	if (!navigation) {
		return null;
	}

	const ID = 'navigation';

	const isMobile =
		document.querySelector('html')?.classList.contains('mobile') ||
		document.querySelector('html')?.classList.contains('tablet');

	const handleOpen = (event: SyntheticEvent<>, data: any) => {
		if (typeof onOpen === 'function') {
			onOpen(event, handleCallbackData(data));
		}
	};

	const handleClose = (event: SyntheticEvent<>, data: any) => {
		if (typeof onClose === 'function') {
			onClose(event, handleCallbackData(data));
		}
	};

	// Handle data returned by onClose callback.
	const handleCallbackData = (data: any) => ({
		id: data.id
	});

	let menu = (
		<Dropdown
			id={`${ID}Menu`}
			tabIndex={false}
			closeOnBlur={false}
			open={open}
			item
			icon={
				<Icon
					name={isMobile && open ? 'close' : 'sidebar'}
					size={isMobile && open ? undefined : Size.Large}
					circular={isMobile && open ? true : false}
				/>
			}
			onOpen={handleOpen}
			onClose={handleClose}
			multiple
		>
			<Dropdown.Menu
				id={`${ID}Dropdown`}
				className={`${ID}Dropdown`}
				style={style}
				onClick={(event) => event.stopPropagation()}
				size='large'
			>
				{tools ? (
					<Segment basic compressed removeMargins={Spacing.All}>
						{tools}
					</Segment>
				) : null}
				<Navigation
					maxLevels={3}
					navigation={navigation}
					raw={raw}
					onLinkClick={(event, data) => {
						if (typeof onLinkClick === 'function') {
							onLinkClick(event, data);
						}
						if (typeof onClose === 'function') {
							onClose(event, data);
						}
					}}
				/>
			</Dropdown.Menu>
		</Dropdown>
	);

	return !open
		? // $FlowIssue - React statics; Attach popup
			Popup.attach({ text: <FormattedMessage id='component.navigation-menu.name' />, ...popup }, menu)
		: menu;
}

export default (React.memo(NavigationMenu): React.AbstractComponent<React.PropsOf<NavigationMenu>, mixed>);
