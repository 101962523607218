/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { MessageHeader as SUIRMessageHeader } from 'semantic-ui-react';

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/message */
component MessageHeader(
	/** Detail as children. */
	children: React.Node,
	/** A message haedr can have different sizes. */
	size?: 'large' | 'small',
	/** Test ID for testing */
	testID?: string = 'MessageHeader'
) {
	let classes = classNames('ui', size);
	return (
		<SUIRMessageHeader data-testid={testID} className={classes}>
			{children}
		</SUIRMessageHeader>
	);
}

export default (React.memo(MessageHeader): React.AbstractComponent<React.PropsOf<MessageHeader>, mixed>);
