/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { useRef, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown as SUIRDropdown } from 'semantic-ui-react';
import { Icon, Image, Flag, Header, Button, Popup, Text } from 'liana-ui/components/';
import { Size } from 'liana-ui/types';

type Option = {
	value: string,
	label?: any,
	text?: string | React.Node,
	content?: React.Node,
	description?: string,
	floatedContent?: React.Node,
	icon?: string | React.PropsOf<Icon>,
	image?: string | React.PropsOf<Image>,
	off?: boolean,
	disabled?: boolean,
	popup?: React.Node | React.PropsOf<Popup>,
	...
};

type Params = {
	name?: string,
	value?: string | Array<string>,
	searchQuery?: string,
	minCharacters?: number,
	multiple?: boolean,
	options?: Array<Option>,
	selectedOptions?: Option | Array<Option>,
	...
};

type Item = {
	flag: 'string' | React.PropsOf<Flag>,
	image: 'string' | React.PropsOf<Image>,
	icon: 'string' | React.PropsOf<Icon>,
	text: 'string' | React.PropsOf<Text>,
	popup: 'string' | React.PropsOf<Popup>
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/dropdown/ */
component Dropdown(
	/** A dropdown must have an input name */
	name: string,
	/**
		A dropdown can have options.
		PROPS[Icon=/components/labels/icons/icon/, Image=/components/texts/image/, Popup=/components/modals/popup/, React.Node=/language/localisation/]
		DATA[json/dropdown/dropdown.json]
	*/
	options?: Array<Option>,
	/** Initial value or value array if multiple. Use for uncontrolled components only. */
	defaultValue?: string | Array<string>,
	/** Current value or value array if multiple. Use for controlled components only. */
	value?: string | Array<string>,
	/**
		An dropdown can have a placeholder text.
		PROPS[React.Node=/localization/]
	*/
	placeholder?: React.Node,
	/** A dropdown can allow adding new options. */
	allowAdditions?: boolean,
	/**
		A dropdown with allowAdditons enabled can display a custom text when adding new item.
		PROPS[React.Node=/localization/]
	*/
	additionText?: React.Node = <FormattedMessage id='component.dropdown.add' />,
	/**
		A dropdown can display a custom message when there are no results.
		PROPS[React.Node=/localization/]
	*/
	noResultsMessage?: React.Node = <FormattedMessage id='component.search-input.noResults' />,
	/** A dropdown can have an icon. */
	icon?: string = 'chevron down',
	/** TODO: Undocumented prop */
	selection: boolean = true,
	/** A dropdown can allow multiple selections. */
	multiple: boolean = false,
	/** A dropdown can be searchable. A dropdown also allows you to provide your own search function. */
	search: boolean = false,
	/** A dropdown can delay onSearchChangeDelay callback. */
	delay: number = 400,
	/** A dropdown can have minimum characters required to query and show remote results. Set at least 1 for remote search dropdowns. */
	minCharacters: number = 1,
	/** A dropdown can be loading when updating options. */
	loading: boolean = false,
	/** A dropdown can be clearable. */
	clearable: boolean = false,
	/** A dropdown menu can be scrollable */
	scrolling: boolean = false,
	/** A dropdown can take the full width of its parent. */
	fluid: boolean = true,
	/** A dropdown can break very long options in tight spaces into multiple lines. */
	breakWord: boolean = false,
	/** An dropdown can be locked to indicate that the field is in use but can not be edited. */
	locked: boolean = false,
	/** A dropdown can be  disabled. */
	disabled: boolean = false,
	/* Whether or not the menu should be initially open */
	open?: boolean,
	/* Whether or not the menu should open when the dropdown is focused. */
	openOnFocus: boolean = true,
	/** A dropdown can be different size. */
	size?: Size,
	/* A dropdown can have additional classes. Use for very special features only! */
	className?: string,
	/**
		Popup text or, react-intl component or object of properties for Popup component.
		PROPS[React.Node=/language/localisation/, Popup=/components/modals/popup/]
	*/
	popup?: React.Node | React.PropsOf<Popup>,
	/** Test ID for testing */
	testID: string = 'Dropdown',
	/** Function called on add item. */
	onAddItem?: (event: SyntheticEvent<>, data: { value: string, options: Array<Option> }) => void,
	/** Function called on dropdown click. */
	onClick?: (event: SyntheticEvent<>, data: Params) => void,
	/** Function called on dropdown focus. */
	onFocus?: (event: SyntheticEvent<>, data: Params) => void,
	/** Function called on bropdown blur. */
	onBlur?: (event: SyntheticEvent<>, data: Params) => void,
	/** Function called on input change. */
	onChange?: (event: SyntheticEvent<>, data: Params) => void,
	/** Function called on search input change. */
	onSearchChange?: (event: SyntheticEvent<>, data: Params) => void,
	/** Function called on search input change after delay. */
	onSearchChangeDelay?: (event: SyntheticEvent<>, data: Params) => void,
	/** TODO: Undocumented prop */
	onKeyDown?: () => void
) {
	// Variables and refs
	const intl = useIntl();
	const dropdownWrapperRef = useRef<HTMLDivElement | null>(null);
	const inputRef = useRef<HTMLInputElement | null>(null);
	let timeoutRef = useRef<TimeoutID | null>(null);

	// Internal states
	const [searchQuery, setSearchQuery] = useState('');
	const [internalValue, setInternalValue] = useState(defaultValue);
	const currentValue = value === undefined ? internalValue : value;

	// Prevent Chrome from using autocomplete on dropdowns: https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
	useEffect(() => {
		if (dropdownWrapperRef.current) {
			let input = dropdownWrapperRef.current.querySelector('input.search');
			if (input) {
				input.setAttribute('autocomplete', 'off,chrome-off');
			}
		}
	}, []);

	// Reset search and cancel delay.
	const resetSearch = () => {
		setSearchQuery('');
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
	};

	// Called on dropdown click.
	const handleClick = (event: SyntheticInputEvent<>) => {
		if (typeof onClick === 'function') {
			onClick(event, handleCallbackData({ value: currentValue, searchQuery: event.target.value }));
		}
	};

	// Called on dropdown focus.
	const handleFocus = (event: SyntheticInputEvent<>) => {
		resetSearch();
		if (typeof onFocus === 'function') {
			onFocus(event, handleCallbackData({ value: currentValue, searchQuery: event.target.value }));
		}
	};

	// Called on dropdown focus.
	const handleBlur = (event: SyntheticInputEvent<>, data: any) => {
		resetSearch();
		if (typeof onBlur === 'function') {
			onBlur(event, handleCallbackData({ ...data, value: currentValue, searchQuery: event.target.value }));
		}
	};

	// Called on input change
	const handleChange = (event: SyntheticEvent<>, data: any) => {
		// Set current value internally
		setInternalValue(data.value);

		// Trigger onChange callback with formatted data
		if (typeof onChange === 'function') {
			onChange(event, handleCallbackData(data));
		}

		// Trigger Form onChange on value change
		if (inputRef && inputRef.current) {
			inputRef.current.dispatchEvent(new Event('input', { bubbles: true }));
			// $FlowIssue[incompatible-use] - Flow breaks here for no good reason
			inputRef.current.dispatchEvent(new Event('change', { bubbles: true }));
		}
	};

	// Called hidden input on change (support for uncontrolled form clear/reset)
	const handleInputChange = (event: SyntheticInputEvent<>) => {
		if (event.target.value) {
			handleChange(event, { value: event.target.value, options: options });
		} else {
			handleClear(event);
		}
	};

	const handleSearchChange = (event: SyntheticEvent<>, data: { value: string, searchQuery: string }) => {
		// Search Dropdown functionality
		if (search) {
			setSearchQuery(data.searchQuery);

			if (typeof onSearchChange === 'function') {
				onSearchChange(
					event,
					handleCallbackData({
						value: data.value,
						searchQuery: data.searchQuery
					})
				);
			}
			// Search Dropdown delay functionality
			if (typeof onSearchChangeDelay === 'function') {
				if (timeoutRef.current) {
					clearTimeout(timeoutRef.current);
				}
				timeoutRef.current = setTimeout(() => {
					onSearchChangeDelay(
						event,
						handleCallbackData({
							value: data.value,
							searchQuery: data.searchQuery
						})
					);
				}, delay);
			}
		}
	};

	// Called on add
	const handleAddItem = (event: SyntheticEvent<>, data: { value: string, options: Array<Option> }) => {
		resetSearch();
		if (typeof onAddItem === 'function') {
			onAddItem(event, {
				value: data.value,
				options: data.options
			});
		}
	};

	// Handle data returned by callbacks.
	const handleCallbackData = (data: {
		value?: string | Array<string>,
		searchQuery?: string,
		options?: Array<string>
	}) => {
		return {
			name: name,
			value: data.value,
			searchQuery: data.searchQuery,
			minCharacters: minCharacters,
			multiple: multiple,
			options: options,
			selectedOptions: getSelectedOptions({ ...data, multiple: multiple })
		};
	};

	const handleClear = (event: SyntheticEvent<>) => {
		handleChange(event, { value: multiple ? [] : '', options: options });
	};

	// Get selected options for callbacks.
	const getSelectedOptions = (data: any): Option | Array<Option> | void => {
		if (options && data.value) {
			if (!data.multiple && data.value !== '') {
				return options.filter((item) => item.value === data.value)[0];
			} else if (data.multiple && data.value.length > 0) {
				let selectedOptions = [];
				if (Array.isArray(data.value)) {
					data.value.forEach((val) => {
						let found = options.filter((item) => item.value === val)[0];
						if (found) {
							selectedOptions.push(found);
						}
					});
				}
				return selectedOptions;
			}
		}
		return undefined;
	};

	// Format option layout
	const formatOptions = (options: any) => {
		let formattedOptions = [];
		if (options && options.length > 0) {
			options.forEach((option, i) => {
				if (option.divider) {
					formattedOptions.push({
						...options[i],
						className: 'divider',
						key: `option-${i}`
					});
				} else if (option.header) {
					formattedOptions.push({
						content: <SUIRDropdown.Header content={option.header} />,
						className: 'header',
						key: `option-${i}`
					});
				} else if (option.description && !option.content) {
					formattedOptions.push({
						...options[i],
						content: (
							<>
								<span className='text'>
									<Header size={Size.Tiny} text={option.text} subheader={option.description} />
								</span>
								{option.floatedContent ? (
									<div className='description nowrap'>{option.floatedContent}</div>
								) : null}
							</>
						),
						icon: option.icon ? getIcon(option.icon) : undefined,
						description: undefined,
						floatedContent: undefined,
						className: `has-description${option.off ? ' off' : ''}`,
						key: `option-${i}`
					});
				} else {
					const content = option.popup
						? // $FlowIssue - React statics; Attach popup
							Popup.attach(option.popup, <span>{option.content || option.text}</span>)
						: option.content || option.text;

					formattedOptions.push({
						...options[i],
						content: (
							<>
								<span className='text'>{content}</span>
								{option.floatedContent ? (
									<div className='description nowrap'>{option.floatedContent}</div>
								) : null}
							</>
						),
						description: undefined,
						floatedContent: undefined,
						className: `${option.off ? 'off' : ''}`,
						icon: option.icon ? getIcon(option.icon) : undefined,
						key: `option-${i}`
					});
				}
			});
		}
		return formattedOptions;
	};

	// Format option layout
	const getIcon = (icon: string | React.PropsOf<Icon>) => {
		if (typeof icon === 'object') {
			return <Icon {...icon} />;
		} else if (typeof icon === 'string') {
			return <Icon name={icon} />;
		}
	};

	// Format placeholder (needs to be string for SUI-R dropdown)
	const formatPlaceholder = (placeholder: any) => {
		let string = '';
		if (typeof placeholder === 'string') {
			string = placeholder;
		} else if (React.isValidElement(placeholder) && placeholder.props?.id) {
			string = intl.formatMessage({ id: placeholder.id });
		}
		return string;
	};

	// Render label
	const renderLabel = (item: Item) => {
		const { flag, image, icon, text, popup } = item;

		// TODO: remove this in v3
		// This maintains compatibility with Shorthand API in v1 as this might be called in "Label.create()"
		if (typeof text === 'function') {
			return text;
		}

		const wrappedContent = (
			// $FlowIssue - Flow does'nt undertsand this kind of declaration
			<span>
				{typeof image === 'object' ? (
					<Image {...image} />
				) : typeof image === 'string' ? (
					<Image src={image} />
				) : null}
				{React.isValidElement(icon) ? (
					icon
				) : typeof icon === 'object' ? (
					<Icon {...icon} />
				) : typeof icon === 'string' ? (
					<Icon name={icon} />
				) : null}
				{typeof flag === 'string' ? <Flag name={flag} /> : null}
				{text}
			</span>
		);

		return {
			// $FlowIssue - React statics; Attach popup
			content: popup ? Popup.attach(popup, wrappedContent) : wrappedContent
		};
	};

	let ifLoading = loading && !disabled && !locked && searchQuery && searchQuery.length >= minCharacters;
	let hasValue = (!multiple && value !== undefined) || (multiple && value && value.length > 0);

	// Assign classes
	let wrapperClasses = classNames(
		{
			'dropdown-wrapper': selection,
			fluid: fluid,
			'break-word': breakWord
		},
		size
	);

	let classes = classNames(
		'text-hyphenate',
		{
			clearable: clearable,
			locked: locked,
			tagged: !multiple && allowAdditions,
			'has-value': hasValue,
			'hide-no-results': loading || (search && searchQuery.length < minCharacters)
		},
		size,
		className
	);

	// Dropdown field
	let dropdown = (
		<div className={wrapperClasses} ref={dropdownWrapperRef}>
			{clearable ? (
				<div>
					<Button
						icon={{ name: 'fa-remove', solid: true }}
						circular
						size={Size.ExtraMini}
						hidden={!currentValue || (multiple && currentValue.length === 0) || disabled || locked}
						onClick={handleClear}
					/>
				</div>
			) : null}
			<SUIRDropdown
				open={open}
				selection={selection}
				pointing={!selection}
				selectOnBlur={false}
				selectOnNavigation={false}
				openOnFocus={openOnFocus}
				additionLabel={
					<span>
						<Icon name='fa-plus' color='black' />
						{additionText}{' '}
					</span>
				}
				className={classes}
				data-default={defaultValue}
				value={currentValue !== undefined ? currentValue : multiple ? [] : undefined}
				options={formatOptions(options)}
				allowAdditions={allowAdditions}
				placeholder={formatPlaceholder(placeholder)}
				fluid={fluid}
				multiple={multiple}
				search={search || allowAdditions ? search : false}
				minCharacters={!options ? minCharacters : undefined}
				loading={ifLoading}
				scrolling={scrolling}
				icon={!locked ? { name: icon } : false}
				disabled={disabled || locked}
				renderLabel={(item) => renderLabel(item)}
				onClick={handleClick}
				onFocus={handleFocus}
				onBlur={handleBlur}
				onAddItem={handleAddItem}
				onChange={handleChange}
				noResultsMessage={noResultsMessage}
				onSearchChange={handleSearchChange}
				onKeyDown={onKeyDown}
				data-testid={testID}
			/>
			<input
				className='hidden'
				name={name}
				value={currentValue ? currentValue : ''}
				disabled={disabled}
				ref={inputRef}
				onChange={handleInputChange}
			/>
		</div>
	);

	// https://github.com/reactjs/react-docgen/issues/336
	// $FlowIssue - React statics; Attach popup
	return <>{Popup.attach(popup, dropdown)}</>;
}

export type { Params, Option };

export default (React.memo(Dropdown): React.AbstractComponent<React.PropsOf<Dropdown>, mixed>);
