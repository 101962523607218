/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { useState, useEffect, useRef } from 'react';
import { Popup as SUIRPopup } from 'semantic-ui-react';
import { Header } from 'liana-ui/components/';
import { Size, Position, On } from 'liana-ui/types/';
import PopupContent from './src/PopupContent';

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/popup */
component Popup(
	/** Element to be rendered in-place where the popup is defined. */
	trigger?: React.Node,
	/**
		A popup can have plain text content.
		PROPS[React.Node=/localization/]
	*/
	text?: React.Node,
	/**
		A popup can have a content header.
		PROPS[React.Node=/localization/]
	*/
	header?: React.Node,
	/** A popup can have other content than plain text. */
	content?: React.Node,
	/** A popup can be open. */
	open?: boolean = false,
	/** A popup can stay open when hovered. Allows for interaction with popup content. */
	hoverable?: boolean = false,
	/** A popup can open on hover or click. */
	on?: On | Array<On> = [On.Hover, On.Click],
	/** A popup can have delay before it is opened. */
	delay?: number = 150,
	/** A popup can have animation duration. */
	duration?: number = 100,
	/**
		A popup can have offset to trigger element.
		DATA[https://react.semantic-ui.com/modules/popup/#usage-offset]
	*/
	offset?: [number, number] = [0, 0],
	/** A popup can remove all inside padding. */
	removePaddings?: boolean = false,
	/** A popup can be wide. */
	wide?: boolean | 'very' = false,
	/** A popup can have different open position. */
	position: Position = Position.TopCenter,
	/** A popup can be different size. */
	size: Size = Size.Tiny,
	/** Stop click events from bubbling to its parent */
	stopPropagation?: boolean = false,
	/** You can manually specify the modal parent container */
	mountNode?: HTMLElement | null,
	/** Test ID used for testing. */
	testID: string = 'Popup',
	/** Called on popup close. */
	onClose?: () => void
) {
	// Variables and refs
	let popupRef = useRef<null | HTMLSpanElement>(null);
	let [isOpen, setOpen] = useState(open); // Toggle show / hide
	let [fade, setFade] = useState(); // Timeout handler for transition
	let [internalPosition, setPosition] = useState(position);
	let setDelay = null; // Timeout handler for delay

	useEffect(() => {
		return () => {
			clearTimeout(fade);
			clearTimeout(setDelay);
		};
	}, []);

	// FIXME: Have to hack Popup transitions as there is no support for them at the moment
	const handleToggle = () => {
		clearTimeout(fade);
		if (typeof duration === 'number' && duration > 0) {
			const id: any = setTimeout(() => setFade(undefined), duration); // TimeoutIDs are opaque
			setFade(id);
		}
	};

	const handleOpen = (event: SyntheticMouseEvent<>) => {
		if (stopPropagation && 'stopPropagation' in event) {
			event.stopPropagation();
			event.preventDefault();
		}
		let windowWidth = window.innerWidth;
		let mousePosition = event.clientX;
		let open = () => {
			handleToggle();
			setOpen(true);

			// Fix buggy SUI-R popup position (https://github.com/Semantic-Org/Semantic-UI-React/issues/3771)
			if (popupRef.current) {
				let popupWidth = popupRef.current.getBoundingClientRect().width + 30 / 2;
				let pos = null;

				if (windowWidth - popupWidth < mousePosition) {
					pos = Position.cast(internalPosition.valueOf().replace(/left|center/, 'right'));
				} else if (mousePosition < popupWidth) {
					pos = Position.cast(internalPosition.valueOf().replace(/right|center/, 'left'));
				}
				setPosition(pos ? pos : internalPosition);
			}
		};
		if (!Array.isArray(on) && on !== On.Click && typeof delay === 'number' && delay > 0) {
			setDelay = setTimeout(open, delay);
		} else {
			open();
		}
	};

	const handleClose = () => {
		clearTimeout(setDelay);
		if (isOpen) {
			handleToggle();
			setOpen(false);
			if (onClose && typeof onClose === 'function') {
				onClose();
			}
		}
	};

	// Assign classes
	const classes = classNames('new-popup transition', {
		fade: fade,
		in: isOpen,
		out: !isOpen,
		visible: isOpen && !fade,
		'remove-paddings': removePaddings,
		'content-popup': !text && content
	});

	return (
		<SUIRPopup
			mountNode={mountNode}
			open={Boolean((isOpen || fade) && (text || content))}
			position={internalPosition}
			offset={offset}
			wide={wide}
			size={size}
			hoverable={hoverable}
			className={classes}
			trigger={trigger}
			on={on}
			data-testid={testID}
			onOpen={handleOpen}
			onClose={handleClose}
		>
			<span ref={popupRef}>
				{content ? (
					<>
						{header ? <Header as='h3' text={header} /> : null}
						{content ? <PopupContent>{content}</PopupContent> : null}
					</>
				) : (
					text
				)}
			</span>
		</SUIRPopup>
	);
}

const MemoComponent = (React.memo(Popup): React.AbstractComponent<React.PropsOf<Popup>, mixed>);

// $FlowIssue - Convenience function
MemoComponent.attach = (popup: string | Props, component: React.Node) => {
	if (typeof popup === 'string' || React.isValidElement(popup)) {
		// $FlowExpectedError[prop-missing]
		return <Popup trigger={component} text={popup} />;
	} else if (typeof popup === 'object') {
		// $FlowExpectedError[cannot-spread-interface]
		return <Popup trigger={component} {...popup} />;
	}
	return component;
};

export { Position, On };

export default MemoComponent;
