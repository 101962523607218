/**
 * @prettier
 * @flow
 */

import { Segment, Header, Text, Label, Icon, IconGroup, LimitLabel } from 'liana-ui/components/';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/segment/ */
component SegmentMenuItem(
	/** Segment can be a link. Opens absolute links in new browser tab and internal links via router. */
	link?: string,
	/** Segment should have a header. */
	header: string,
	/** 
			 Segment header can have a icon. 
			 PROPS[Icon=/components/labels/icons/icon]
		*/
	icon?: string | React.PropsOf<Icon>,
	/** 
			 Segment header can have a icon group. 
			 PROPS[IconGroupProps=/components/labels/icons/icon-group]
		*/
	iconGroup?: React.PropsOf<IconGroup>,
	/** Segment header can have labels. */
	labels: React.PropsOf<Label> | Array<React.PropsOf<Label> | React.PropsOf<LimitLabel>>,
	/** Segment content. */
	content?: React.Node,
	/** Called on click. */
	onClick?: (event: SyntheticEvent<>) => void,
	/** Adds upsell icon to the item */
	upsell?: boolean,
	/** Test ID for testing */
	testID: string = 'SegmentMenuItem'
) {
	let item = (
		<Segment raised link={link} onClick={onClick} testID={testID}>
			{header && (
				<Header as='h3' text={header} icon={icon} iconGroup={iconGroup} labels={labels} upsell={upsell} />
			)}
			<Text as='p'>{content}</Text>
		</Segment>
	);

	return item;
}

export default (React.memo(SegmentMenuItem): React.AbstractComponent<React.PropsOf<SegmentMenuItem>, mixed>);
