/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { ButtonGroup } from 'semantic-ui-react';
import { Button, Responsive, Popup } from 'liana-ui/components/';
import { Device, Float, Size } from 'liana-ui/types';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/button/#groups */
component Component(
	/**
		A button group can have multiple buttons.
		PROPS[Button=/components/buttons/button/]
	*/
	buttons?: Array<React.PropsOf<Button>>,
	/** A button group can have special buttons as children. */
	children?: React.Node,
	/** A button group can appear circular. */
	circular?: boolean = true,
	/** A button group can be pronounced by having no borders. */
	basic?: boolean,
	/** A button group can be primary color. */
	color?: 'primary',
	/** A button group can show it is off and currently unnecessary to be interacted with. */
	off?: boolean = false,
	/** A button groups can show it is currently disabled and unable to be interacted with. */
	disabled?: boolean = false,
	/** A button groups can take the whole width of its container. */
	fluid?: boolean = false,
	/** A button group can have no empty space around it. */
	fitted?: boolean,
	/**  A button can be aligned to the left or right of its container. */
	floated?: Float,
	/** A button group can be more compact. */
	compact?: boolean | 'very' = false,
	/** A button group can have button widths divided evenly by amount of buttons. VALUES[1 - 12] */
	widths?: number,
	/** A button group can have different sizes. */
	size?: Size,
	/** Smallest device that component will be displayed with. */
	minDevice?: Device,
	/** Largest device that component will be displayed with. */
	maxDevice?: Device,
	/** Hide content on touch devices */
	hideTouch?: boolean = false,
	/**
		Popup text or, react-intl component or object of properties for Popup component.
		PROPS[React.Node=/language/localisation/, Popup=/components/modals/popup/]
	*/
	popup?: React.Node | React.PropsOf<Popup>,
	/** Test ID for testing */
	testID: string = 'ButtonGroup',
	/** Function called on button click. */
	onClick?: (
		event: SyntheticEvent<>,
		data: {
			name: string,
			off: boolean
		}
	) => void
) {
	// Function to generate LianaUI ButtonGroup
	const createButtonGroup = () => {
		// Assign classes
		const classes = classNames({
			off: off,
			disabled: disabled,
			circular: circular,
			fitted: fitted,
			two: fluid && buttons && buttons.length === 2,
			tree: fluid && buttons && buttons.length === 3,
			four: fluid && buttons && buttons.length === 4,
			five: fluid && buttons && buttons.length === 5
		});

		// Define ButtonGroup
		let buttonGroup = (
			<ButtonGroup
				className={classes}
				primary={color === 'primary'}
				compact={compact}
				basic={basic}
				fluid={fluid}
				floated={floated}
				size={size}
				widths={widths}
				data-testid={testID}
			>
				{children
					? children
					: buttons
						? buttons.map((button, index) => (
								<Button
									key={index}
									color={color}
									off={off}
									disabled={disabled}
									basic={basic}
									circular={circular}
									onClick={onClick}
									testID={`ButtonGroup::Button::${index}`}
									{...button}
								/>
							))
						: null}
			</ButtonGroup>
		);

		// $FlowIssue - Attach popup; React statics
		return Popup.attach(popup, buttonGroup);
	};

	// Display reponsively
	let component =
		minDevice || maxDevice || hideTouch ? (
			<Responsive hideTouch={hideTouch} minDevice={minDevice} maxDevice={maxDevice}>
				{createButtonGroup()}
			</Responsive>
		) : (
			createButtonGroup()
		);

	return component;
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
