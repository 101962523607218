/**
 * @prettier
 * @flow
 */

import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Popup as SUIRPopup } from 'semantic-ui-react';
import { Button, Menu, Icon, Checkbox, Popup } from 'liana-ui/components/';
import { Device, Size, Float } from 'liana-ui/types';

type Option = {
	text?: React.Node,
	header?: React.Node,
	value?: string,
	icon?: string | React.PropsOf<Icon> | Array<string>,
	icons?: Array<string> | Array<React.PropsOf<Icon>>,
	divider?: boolean,
	checkbox?: React.PropsOf<Checkbox>,
	link?: string,
	disabled?: boolean,
	onClick?: (event: SyntheticEvent<>, data: any) => void
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/dropdown/#types-pointing */
component Component(
	/**
		A context menu must have options.
		PROPS[React.Node=/language/localisation/, Checkbox=/components/forms/checkbox/, Icon=/components/labels/icons/icon/]
	*/
	options: Array<Option>,
	/**
		A context menu button can have different icon.
		PROPS[Icon=/components/labels/icons/icon/]
	*/
	icon?: string | React.PropsOf<Icon> = 'fa-ellipsis',
	/**
		A context menu button can have different icon group.
		PROPS[IconGroupProps=/components/labels/icons/icon-group/]
	*/
	iconGroup?: Array<React.PropsOf<Icon>>,
	/**
		A context menu button can have text.
		PROPS[React.Node=/localization/]
	*/
	text?: React.Node,
	/** A context menu button can have primary color. */
	color?: 'primary',
	/**  A context menu button can be aligned to the left or right of its container. */
	floated?: Float,
	/** A context menu button can have no empty space around it. */
	fitted?: boolean = false,
	/** A context menu button can be pronounced by having no borders. */
	basic?: boolean,
	/** A context menu button can show it is currently unnecessary to be interacted with. */
	off?: boolean = false,
	/** A context menu button can show it is currently unable to be interacted with. */
	disabled?: boolean = false,
	/** A content menu can stay open on menu item clicks to allow selecting multiple items. */
	keepOpen?: boolean = false,
	/** A content menu can be forced to open to the left or to the right. */
	direction?: Float = Float.Right,
	/** A button can have different sizes. */
	size?: Size,
	/** Smallest device that component will be displayed with. */
	minDevice?: Device,
	/** Largest device that component will be displayed with. */
	maxDevice?: Device,
	/** Hide content on touch devices */
	hideTouch?: boolean = false,
	/**
		Popup text or, react-intl component or object of properties for Popup component.
		PROPS[React.Node=/language/localisation/, Popup=/components/modals/popup/]
	*/
	popup?: React.Node | React.PropsOf<Popup> = <FormattedMessage id='component.context-menu.actions' />,
	/** Test ID for testing */
	testID: string = 'ContextMenuDetached',
	/** Function called on menu open. */
	onOpen?: (event: SyntheticEvent<>, data: any) => void,
	/** Function called on menu close. */
	onClose?: (event: SyntheticEvent<>, data: any) => void
) {
	// Variables and refs
	let [open, setOpen] = useState(false);

	const handleOpen = (event: SyntheticEvent<>, data: any) => {
		// Open menu
		setOpen(true);
		// Trigger onClick callback funtion
		if (typeof onOpen === 'function') {
			onOpen(event, data);
		}
	};

	const handleClose = (event: SyntheticEvent<>, data: any, keepOpen: boolean = false) => {
		// Wait for dropdown tp close
		if (!keepOpen) {
			setOpen(false);
		}

		// Trigger onClick callback funtion
		if (typeof onClose === 'function') {
			onClose(event, data);
		}
	};

	const formatOptions = (options: any) => {
		let formattedOptions = [];
		if (options && options.length > 0) {
			options.forEach((option, i) => {
				formattedOptions.push({
					...options[i],
					onClick: (event: SyntheticEvent<>, data: any) => {
						handleClose(event, data, keepOpen);
						if (typeof options[i].onClick === 'function') {
							options[i].onClick();
						}
					}
				});
			});
		}
		return formattedOptions;
	};

	let button = (
		<Button
			className='context-menu-detached'
			icon={!iconGroup ? icon : undefined}
			iconGroup={iconGroup}
			focused={open}
			text={text}
			circular
			color={color}
			basic={basic}
			disabled={disabled}
			fitted={fitted}
			floated={floated}
			off={off}
			size={size}
			minDevice={minDevice}
			maxDevice={maxDevice}
			hideTouch={hideTouch}
			popup={!open || off ? popup : undefined}
		/>
	);

	let content = <Menu options={formatOptions(options)} compressed rounded={false} testID={testID} />;

	return (
		<SUIRPopup
			className='remove-paddings'
			trigger={button}
			content={content}
			on='click'
			position={`bottom ${direction === Float.Left ? 'right' : 'left'}`}
			open={open}
			onClose={handleClose}
			onOpen={handleOpen}
		/>
	);
}

export type { Option };

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
