/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Responsive, Popup } from 'liana-ui/components/';
import { Size, HAlign, Device } from 'liana-ui/types';

/** COMPONENT BASED ON: https://fomantic-ui.com/elements/text.html */
component Text(
	/** Content as children. */
	children?: React.Node,
	/** An element type to render as. */
	as: 'span' | 'div' | 'p' | 'sup' | 'label' = 'span',
	/** Text can be bold. */
	bold: boolean = false,
	/** Text can be bolder. */
	bolder: boolean = false,
	/** Text can be light. */
	light: boolean = false,
	/** Text can be lighter. */
	lighter: boolean = false,
	/** Text can be italic. */
	italic: boolean = false,
	/** Text can be underlined. */
	underline: boolean = false,
	/** Text can be different color. */
	color?: 'primary' | 'black' | 'grey' | 'red' | 'orange' | 'yellow' | 'green' | 'blue',
	/** Text can be highlighted. */
	highlight: boolean | 'code' = false,
	/** Text can vary in Size. */
	size?: Size,
	/** Text can have different vertical align in container. */
	textAlign?: HAlign,
	/** A plain text can be limited to maximum amount of characters followed by ellipsis. */
	limit?: number,
	/** 
			 A plain text can be limited to maximum amount lines followed by ellipsis. 
			 VALUES[1 - 10]
		*/
	limitLines: number = 0,
	/** A text can try to break long words. */
	breakWord: boolean = false,
	/** A text can always keep all content on a single line. */
	singleLine: boolean = false,
	/** Additional class names */
	className?: string,
	/** Smallest device that component will be displayed with. */
	minDevice?: Device,
	/** Largest device that component will be displayed with. */
	maxDevice?: Device,
	/** Hide content on touch devices */
	hideTouch?: boolean,
	/**
			Popup text or, react-intl component or object of properties for Popup component.
			PROPS[React.Node=/language/localisation/, Popup=/components/modals/popup/]
		*/
	popup?: React.Node | React.PropsOf<Popup>,
	/** Test ID for testing */
	testID: string = 'Text'
) {
	const Element = as; // Lowercase values will be applied literally in JSX
	const allowed = ['span', 'div', 'p', 'sup', 'label'];
	const createText = () => {
		// Assign classes
		let classes = classNames(
			'ui text',
			{
				'text-highlighted': highlight,
				'text-code': highlight === 'code',
				'text-light': light,
				'text-lighter': lighter,
				'text-bold': bold,
				'text-bolder': bolder,
				'text-italic': italic,
				'text-underline': underline,
				'text-left': textAlign === HAlign.Left,
				'text-center': textAlign === HAlign.Center,
				'text-right': textAlign === HAlign.Right,
				'text-hyphenate': breakWord,
				'nowrap overflow-ellipsis': singleLine,
				[`text-lines-${limitLines}`]: Boolean(limitLines)
			},
			color,
			size,
			className
		);

		// Limit text to maximum amount of characters
		let modChildren = children,
			modPopup = popup;
		if (limit && typeof children === 'string' && children.length > limit) {
			modChildren = children.substring(0, limit) + '...';
			if (typeof popup === 'object') {
				// $FlowFixMe - Bad substitution; incompatible with React.Node and/or string
				modPopup = { ...popup, size: 'tiny', text: children, delay: 500 };
			}
		}
		const common = { className: classes, testID: testID };

		// Create text
		let text =
			Element && allowed.indexOf(Element) > 0 ? (
				<Element {...common}>{modChildren}</Element>
			) : (
				<span {...common}>{modChildren}</span>
			);

		// $FlowIssue - React statics; Attach popup
		return Popup.attach(modPopup, text);
	};

	// Display reponsively
	let component =
		minDevice || maxDevice || hideTouch ? (
			<Responsive minDevice={minDevice} maxDevice={maxDevice} hideTouch={hideTouch}>
				{createText()}
			</Responsive>
		) : (
			createText()
		);

	return component;
}

export default (React.memo(Text): React.AbstractComponent<React.PropsOf<Text>, mixed>);
