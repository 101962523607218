/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Grid as SUIRGrid } from 'semantic-ui-react';
import GridRow from './GridRow';
import GridColumn from './GridColumn';
import { Device, HAlign, VAlign } from 'liana-ui/types';

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/grid/ */
component Grid(
	/** Grid content as children. */
	children: React.Node,
	/** A grid column can have the id attribute */
	id?: string,
	/**
		Represents column count per row in Grid.
		VALUES['equal' | 1 - 12]
	*/
	columns?: string | number,
	/** A grid can have its columns stack on-top of each other after reaching mobile (boolean) or tablet ('tablet') breakpoints. */
	stackable?: boolean | 'tablet' = false,
	/** A grid can stretch its contents to take up the entire grid height. */
	stretched?: boolean = false,
	/** A grid can be forced to take full available space in container. */
	fluid?: boolean,
	/** A grid can have dividers between its columns. Vertical requires Grid.Row for each row. */
	divided?: boolean | 'vertically' = false,
	/** A grid can have dividers between its columns and rows. */
	celled?: boolean | 'internally',
	/** A grid can double its column width on tablet and mobile sizes. */
	doubling?: boolean = false,
	/** A grid can preserve its vertical and horizontal gutters on first and last columns. */
	padded?: boolean | 'horizontally' | 'vertically' = false,
	/** A grid can increase its gutters to allow for more negative space. */
	relaxed?: boolean | 'very' = false,
	/** A grid can have its columns centered. */
	centered?: boolean,
	/** A grid can appear compact with no or very little padding */
	compact?: boolean | 'very' = false,
	/** A grid can always keep all columns on a single row. */
	singleRow?: boolean = false,
	/** A grid can specify that its contents should remain on a single line, and not wrap. */
	singleLine?: boolean = false,
	/** A grid can break very long strings in tight spaces into multiple lines. */
	breakWord?: boolean = false,
	/** A grid can specify that its columns should reverse order at different device sizes. */
	reversed?: Device,
	/** A grid can specify its text alignment. */
	textAlign?: HAlign,
	/** A grid can specify its vertical alignment to have all its columns vertically centered. */
	verticalAlign?: VAlign,
	/* A grid title can have additional classes. */
	className?: string,
	/** Test ID for testing */
	testID: string = 'Grid'
) {
	const classes = classNames(
		{
			compact: compact,
			fluid: fluid,
			very: compact === 'very',
			'single-row': singleRow,
			'break-word': breakWord,
			'stackable-tablet': stackable === 'tablet'
		},
		className
	);

	let grid = (
		<SUIRGrid
			id={id}
			className={classes}
			columns={columns}
			stackable={stackable}
			stretched={stretched}
			divided={divided}
			celled={celled}
			doubling={doubling}
			padded={padded}
			relaxed={relaxed}
			centered={centered}
			reversed={reversed}
			textAlign={textAlign}
			verticalAlign={verticalAlign}
			data-testid={testID}
		>
			{children}
		</SUIRGrid>
	);

	if (singleLine) {
		grid = <div className='break-word-wrap'>{grid}</div>;
	}

	return grid;
}

const MemoComponent = (React.memo(Grid): React.AbstractComponent<React.PropsOf<Grid>, mixed>);
// $FlowIssue - Static subcomponents
MemoComponent.Row = GridRow;
// $FlowIssue - Static subcomponents
MemoComponent.Column = GridColumn;

export default MemoComponent;
