/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import EmptyStateGroup from './EmptyStateGroup';
import { Segment, Image, Header, Button } from 'liana-ui/components/';
import { Size, Spacing } from 'liana-ui/types';

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/message/ */
component Component(
	/**
		An empty state must have an image.
		DATA[https://www.figma.com/file/VnqiJJznuDBiqEen7wgqMX/Illustrations?type=design&node-id=1890-6959&t=HRjyAMWxUduyrTwy-0]
	 */
	image: string,
	/**
			An empty state must have a header.
			PROPS[React.Node=/localization/]
		*/
	header: React.Node,
	/** An empty state must have content. */
	content: React.Node,
	/** 
			  An empty state can have buttons.
			PROPS[Button=/components/buttons/button/]
		*/
	buttons?: React.PropsOf<Button> | Array<React.PropsOf<Button>>,
	/** An empty state can be in a segment. */
	segment?: boolean = false,
	/** An segment empty state can be highlighted. */
	highlighted?: boolean = false,
	/** Test ID for testing */
	testID?: string = 'EmptyState'
) {
	// Assign classes
	const classes = classNames('empty-state text-center', {
		highlighted: highlighted
	});

	let message = (
		<Segment
			basic={!segment && !highlighted}
			raised={segment || highlighted}
			removePaddings={!segment && !highlighted ? Spacing.All : undefined}
			size={highlighted ? Size.Large : undefined}
			className={classes}
			testID={testID}
		>
			<div className='empty-state-container'>
				<div className='empty-state-content'>
					{image ? <Image src={image} size={Size.Small} circular /> : null}
					{header ? <Header as='h2' text={header} /> : null}
					{content ? <p>{content}</p> : null}
				</div>
			</div>
			{buttons ? (
				<div className='empty-state-buttons'>
					{Array.isArray(buttons) ? (
						buttons.map((button, index) => <Button key={index} fitted={buttons.length === 1} {...button} />)
					) : buttons ? (
						<Button fitted={true} {...buttons} />
					) : null}
				</div>
			) : null}
		</Segment>
	);

	return message;
}

const MemoComponent = (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
// $FlowIssue - Static subcomponents
MemoComponent.Group = EmptyStateGroup;

export default MemoComponent;
