/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Image as SUIRImage } from 'semantic-ui-react';
import { Icon, Placeholder, Popup, Responsive, PlaceholderImage } from 'liana-ui/components';
import { Size, Device, Float, VAlign } from 'liana-ui/types';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/image */
component Image(
	/** An image must have an relative or absolute url. */
	src: string = '',
	/**
		An image must have an alternative text.
	*/
	alt?: React.Node,
	/**
		An image can be formatted to appear as an circular user avatar. Use string to provide an fallback icon for non user avatars.
		VALUES[false | true | 'building' | 'globe' | ...]
	*/
	avatar?: boolean | string = false,
	/** An image can appear circular. */
	circular?: boolean = false,
	/** An image can appear squared. */
	squared?: boolean = false,
	/** An image can appear inline. */
	inline?: boolean = false,
	/** An image can be formatted to appear on dark backgrounds. */
	inverted?: boolean = false,
	/** An image can take up the width of its container. */
	fluid?: boolean = false,
	/** An image can be fitted without space to left or right of image. */
	fitted?: boolean,
	/** An image can be lazyloaded with a skeleton element. 'empty' will not show skeleton but empty space. */
	lazyLoad?: boolean | 'empty' = true,
	/** Max height of responsive images */
	maxHeight?: string,
	/** Width/Height ratio for fluid responsive image placeholder loaders. */
	ratio?: string = '1000/1000',
	/** An image can appear centered in a content block. */
	centered?: boolean = false,
	/** An image can sit to the left or right of other content. */
	floated?: Float,
	/** An image can be vertivcally aligned. */
	verticalAlign?: VAlign,
	/** An image can have different sizes. */
	size?: Size,
	/**
		Popup text or, react-intl component or object of properties for Popup component.
		PROPS[Popup=/components/modals/popup/]
	*/
	/** Smallest device that component will be displayed with. */
	minDevice?: Device,
	/** Largest device that component will be displayed with. */
	maxDevice?: Device,
	/**
		Popup text or, react-intl component or object of properties for Popup component.
		PROPS[Popup=/components/modals/popup/]
	*/
	popup?: React.Node | React.PropsOf<Popup>,
	/** Hard-coded styles - Use for very special features only! */
	style?: { [string]: string | number },
	/** Hide content on touch devices */
	hideTouch?: boolean,
	/** Test ID used for testing. */
	testID?: string,
	/** Function called on click. */
	onClick?: () => void,
	/** Function called when image has finished loading. */
	onLoad?: () => void
) {
	// Variables and refs
	let [isVisible, setVisible] = useState(lazyLoad ? false : true);
	let [isLoading, setLoading] = useState(lazyLoad ? true : false);
	let [hasError, setError] = useState(false);

	const handleClick = () => {
		if (typeof onClick === 'function') {
			onClick();
		}
	};

	// Function to generate LianaUI Image
	const createImage = (classes: string, styles: { [string]: string | number }) => {
		let image = (
			<SUIRImage
				src={src}
				alt={alt ? alt : avatar ? 'Avatar' : 'Image'}
				avatar={avatar}
				rounded={!squared}
				circular={circular || (avatar && !squared)}
				inline={inline}
				fluid={fluid}
				centered={centered}
				floated={floated}
				verticalAlign={verticalAlign}
				size={size}
				className={classes}
				style={styles}
				onClick={handleClick}
				data-testid={testID}
				onError={() => (lazyLoad === true ? setError(true) : {})}
				onLoad={() => {
					if (lazyLoad) {
						if (typeof onLoad === 'function') {
							onLoad();
						}
						setLoading(false);
					}
				}}
			/>
		);

		// $FlowIssue - React statics; Attach popup
		return Popup.attach(popup, image);
	};

	// Function to generate LianaUI Button
	const createComponent = () => {
		let placeholderClasses = classNames({
			image: !fluid,
			avatar: avatar,
			empty: lazyLoad === 'empty',
			circular: circular || (avatar && !squared),
			rounded: !squared,
			floated: floated,
			right: floated === Float.Right,
			left: floated === Float.Left,
			'remove-margins': fitted,
			// $FlowIgnore enum
			[size]: Boolean(size)
		});

		let iconClasses = classNames({
			'cursor-pointer': onClick
		});

		let classes = classNames({
			hidden: isLoading && lazyLoad,
			'remove-margins': fitted,
			'cursor-pointer': onClick
		});

		let styles = {
			...style,
			maxHeight: maxHeight ? maxHeight : ''
		};

		let component =
			avatar && !src ? (
				<Icon
					avatar
					className={iconClasses}
					name={avatar === true ? 'fa-user' : avatar}
					circular={circular || (avatar && !squared)}
					squared={squared}
					inverted={inverted}
					fitted={fitted}
					size={size === Size.Medium ? 'medium-size' : size}
					popup={popup}
					onClick={handleClick}
				/>
			) : (
				<>
					{isLoading && lazyLoad ? (
						<Placeholder
							className={placeholderClasses}
							error={hasError}
							errorText={<FormattedMessage id='component.image.noImage' />}
							onTopVisible={() => setVisible(true)}
						>
							<PlaceholderImage ratio={ratio} maxHeight={maxHeight} size={size} />
						</Placeholder>
					) : null}
					{isVisible && (!hasError || !lazyLoad) ? createImage(classes, styles) : null}
				</>
			);

		return component;
	};

	// Display reponsively
	let component =
		minDevice || maxDevice || hideTouch ? (
			<Responsive minDevice={minDevice} maxDevice={maxDevice} hideTouch={hideTouch}>
				{createComponent()}
			</Responsive>
		) : (
			createComponent()
		);

	return component;
}

// Component render conditions. True if should not render.
const EQUALS = (prev: React.PropsOf<Image>, next: React.PropsOf<Image>) =>
	prev.src === next.src && prev.alt === next.alt && prev.inverted === next.inverted;

export default (React.memo(Image, EQUALS): React.AbstractComponent<React.PropsOf<Image>, mixed>);
